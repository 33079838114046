var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pl-4",attrs:{"cols":"6"}},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"max-height":"89vh position: relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('table',{attrs:{"width":"100%"}},[_c('tr',[_c('th',{staticClass:"font-size-18 py-2",attrs:{"colspan":"2"}},[_vm._v("Cross Rent Info")])]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2",attrs:{"width":"180"}},[_vm._v(" Rental Cost ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_cost &&
                  _vm.detail.cross_rent_cost != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_c('v-chip',{attrs:{"outlined":"","text-color":"","label":"","small":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.detail.cross_rent_cost)+" "+_vm._s(_vm.detail?.cross_rental_cost_currency && _vm.detail.cross_rental_cost_currency != "null" ? _vm.detail.cross_rental_cost_currency : null)+" ")])],1)]:[_vm._v(" no cost ")]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2",attrs:{"valign":"top"}},[_vm._v(" Rental Invoice Number ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_invoice_number &&
                  _vm.detail.cross_rent_invoice_number != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.cross_rent_invoice_number))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no rental invoice number")])]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2",attrs:{"valign":"top"}},[_vm._v(" Remark ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_remark &&
                  _vm.detail.cross_rent_remark != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.cross_rent_remark))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no remark")])]],2)]),(false)?_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Contract No.")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_contract_number &&
                  _vm.detail.cross_rent_contract_number != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.cross_rent_contract_number))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no contract no.")])]],2)]):_vm._e(),(false)?_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Finance Company")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_finance_company &&
                  _vm.detail.cross_rent_finance_company != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_c('Barcode',{attrs:{"route":"supplier.detail","barcode":_vm.detail.cross_rent_finance_barcode,"id":_vm.detail.cross_rent_finance_company}})],1)]:[_c('span',{staticClass:"text-muted"},[_vm._v("no finance company")])]],2)]):_vm._e(),(false)?_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Finance Company Name ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_finance_company_name &&
                  _vm.detail.cross_rent_finance_company_name != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.detail.cross_rent_finance_company_name))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no finance company name")])]],2)]):_vm._e(),(false)?_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Finance Start Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_finance_start_date &&
                  _vm.detail.cross_rent_finance_start_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.cross_rent_finance_start_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no finance start date")])]],2)]):_vm._e(),(false)?_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Finance End Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_finance_end_date &&
                  _vm.detail.cross_rent_finance_end_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.cross_rent_finance_end_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no finance end date")])]],2)]):_vm._e(),(false)?_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Insurance Company ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_insurance_company &&
                  _vm.detail.cross_rent_insurance_company != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.cross_rent_insurance_company))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no insurance company")])]],2)]):_vm._e(),(false)?_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Insurance Policy ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_insurance_policy &&
                  _vm.detail.cross_rent_insurance_policy != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.cross_rent_insurance_policy))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no insurance policy")])]],2)]):_vm._e(),(false)?_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Insurance Start Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_insurance_start_date &&
                  _vm.detail.cross_rent_insurance_start_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.cross_rent_insurance_start_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no insurance start date")])]],2)]):_vm._e(),(false)?_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Insurance End Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_insurance_end_date &&
                  _vm.detail.cross_rent_insurance_end_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.cross_rent_insurance_end_date)))])]:[_c('span',{staticClass:"text-muted"},[_vm._v("no insurance end date")])]],2)]):_vm._e()])])],1),_c('v-col',{staticClass:"pb-0 pl-4",attrs:{"cols":"6"}},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"max-height":"89vh position: relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('table',{attrs:{"width":"100%"}},[_c('tr',[_c('th',{staticClass:"font-size-18 py-2",attrs:{"colspan":"2"}},[_vm._v(" Supplier Rental Info ")])]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Supplier Name")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_supplier_name &&
                  _vm.detail.cross_rent_supplier_name != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.detail.cross_rent_supplier_name))])]:[_vm._v(" no supplier name ")]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v(" Rental Start Date ")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_start_date &&
                  _vm.detail.cross_rent_start_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.cross_rent_start_date)))])]:[_vm._v(" no rental start date ")]],2)]),_c('tr',{staticClass:"line-height5"},[_c('td',{staticClass:"font-size-18 font-weight-500 py-2"},[_vm._v("Rental End Date")]),_c('td',{staticClass:"font-weight-600 font-size-18 py-2"},[(
                  _vm.detail &&
                  _vm.detail.cross_rent_end_date &&
                  _vm.detail.cross_rent_end_date != 'null'
                )?[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.formatDate(_vm.detail.cross_rent_end_date)))])]:[_vm._v(" no rental end date ")]],2)])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }